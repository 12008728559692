import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import CurrencyFlag from '../countries/CurrencyFlag';

const CreditLineTooltip = ({ totalCredit, creditUsed, available, isoCode }) => (
  <Box
    sx={{
      bgcolor: 'grey.900',
      color: 'common.white',
      p: 1.5,
    }}
  >
    <Stack direction="row" spacing={1} alignItems="center" mb={1}>
      <CurrencyFlag isoCode={isoCode} />
    </Stack>
    <Stack spacing={1.5}>
      <Box>
        <Typography variant="caption" color="grey.400">
          Total línea:
        </Typography>
        <Typography variant="body2">
          {totalCredit}
        </Typography>
      </Box>

      <Stack direction="row" spacing={2}>
        <Box>
          <Typography variant="caption" color="grey.400">
            Utilizado:
          </Typography>
          <Typography variant="body2">
            {creditUsed}
          </Typography>
        </Box>
        <Box>
          <Typography variant="caption" color="grey.400">
            Disponible:
          </Typography>
          <Typography variant="body2">
            {available}
          </Typography>
        </Box>
      </Stack>
    </Stack>
  </Box>
);

CreditLineTooltip.propTypes = {
  totalCredit: PropTypes.string.isRequired,
  creditUsed: PropTypes.string.isRequired,
  available: PropTypes.string.isRequired,
  isoCode: PropTypes.string.isRequired,
};

export default CreditLineTooltip;
